export const ENV = {
  VERSION: '1.0',
  API_VERSION: '2.0',
  PUBLIC_STRIPE_KEY: 'pk_live_jPIp7VtQcSPLJIBYoxIQjCwv00YbiAeSYq',
  ENDPOINT: 'https://api.youbumerang.com/api/v3/',
  CURRENT_ENV: 'production',
  CRISP_ID: 'ed9a8769-ce9b-402c-849d-02d52da57337',
  GOOGLE_MAPS_API_KEY: 'AIzaSyBTjxOuFMQ5di7Cz24GzPDF28wBBUyKKQA',
  FIREBASE_SENDER_ID: '191951374251',
  SEGMENT_WRITE_KEY: 'P3eMtNK2hnQQz6lvUKsqvCbXNZwyoOLB',
  AIRTABLE_API_KEY: 'keyHNAlTsIIIX6BIo',
  STRIPE_ACCOUNT_ID: 'acct_1FKnueF03L74ac1E',
  FIREBASE_API_KEY:
    'eyJhcGlLZXkiOiJBSXphU3lCVGp4T3VGTVE1ZGk3Q3oyNEd6UERGMjh3QkJVeUtLUUEiLCJhdXRoRG9tYWluIjoicXVpY2stZ3VydS0yNzA4MTguZmlyZWJhc2VhcHAuY29tIiwiZGF0YWJhc2VVUkwiOiJodHRwczovL3F1aWNrLWd1cnUtMjcwODE4LWRlZmF1bHQtcnRkYi5ldXJvcGUtd2VzdDEuZmlyZWJhc2VkYXRhYmFzZS5hcHAiLCJwcm9qZWN0SWQiOiJxdWljay1ndXJ1LTI3MDgxOCIsInN0b3JhZ2VCdWNrZXQiOiJxdWljay1ndXJ1LTI3MDgxOC5hcHBzcG90LmNvbSIsIm1lc3NhZ2luZ1NlbmRlcklkIjoiMTkxOTUxMzc0MjUxIiwiYXBwSWQiOiIxOjE5MTk1MTM3NDI1MTp3ZWI6ZDMwZGE0MDkwZjdlOGFjYWQxMzRlZSJ9Cg=='
};
